import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ApolloQueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import { DateField, DropdownField, RangeContainer, TextField } from 'src/components/formik-fields/formik-fields';
import { PaginationVariables } from 'src/components/table-controls/table-controls';
import { Body } from 'src/components/typography/typography';
import { GENERATE_RANK_LIST, RANK_LIST_JOB } from 'src/components/usta-rankings/usta-ranking-queries';
import {
  AgeRestrictionEnum,
  ListTypeEnum,
  PlayerLevelEnum,
  PlayerTypeEnum,
  RankListGenderEnum,
} from 'src/graphql-types/globalRankingTypes';
import { getFirstOptionValue } from 'src/utils/helper/membership';
import { DivisionTypesEnum, useCircuitOptions, useRankingOptions } from 'src/utils/helper/rankings';

import { BodyRegularBold } from '@clubspark-react/clubspark-react-tools';

import * as styles from './usta-ranking-dialog.module.less';
import {
  displayDateRange,
  getAgeRestriction,
  getDateRange,
  getDirectAcceptanceItemLimit,
  getFamilyCategory,
  getGender,
  getMatchFormat,
  getNTRPLevelOptions,
  getPlayerLevel,
  getQuotaSection,
  getRunDate,
} from './usta-rankings-dialog.utils';

interface InitialValues {
  startDate: Date;
  endDate: Date;
  runDate: Date;
  circuit: PlayerTypeEnum;
  type: ListTypeEnum;
  gender: string;
  section?: string;
  ageRestriction: string;
  format: string;
  ntrpLevel: string;
  divisionType?: string;
  itemLimit?: string;
}

interface RankingDialogInterface {
  setRankingDialog: Dispatch<SetStateAction<boolean>>;
  refetch?: (variables?: PaginationVariables | undefined) => Promise<ApolloQueryResult<any>>;
}

export const USTARankingDialog = ({ setRankingDialog, refetch }: RankingDialogInterface) => {
  const { t } = useTranslation();
  const [polling, setPolling] = useState(false);

  const [generateRankList, { loading: generatingRankList, error }] = useMutation(GENERATE_RANK_LIST, {
    client: meshGatewayClient,
  });

  const [checkRankListStatus, { data, stopPolling, error: rankListError }] = useLazyQuery(RANK_LIST_JOB, {
    pollInterval: 3000,
    client: meshGatewayClient,
  });

  useEffect(() => {
    const rankListJob = data?.rankListJob;
    const hasErrors = rankListJob?.jobErrors?.length !== 0;

    if (!rankListJob?.jobComplete) return;

    if (!rankListError && !hasErrors) {
      stopPolling();
      setPolling(false);
      setRankingDialog(false);
      refetch?.();
    } else {
      stopPolling();
      setPolling(false);
    }
  }, [data, rankListError]);

  const circuitOptions = useCircuitOptions();

  const [circuit, setCircuit] = useState<PlayerTypeEnum>(PlayerTypeEnum.ADULT);
  const [divisionType, setDivisionType] = useState<DivisionTypesEnum>(DivisionTypesEnum.AGE);
  const [ageGroup, setAgeGroup] = useState<AgeRestrictionEnum>(AgeRestrictionEnum.y18);
  const [gender, setGender] = useState<RankListGenderEnum>(RankListGenderEnum.M);
  const [dates, setDates] = useState<Record<'startDate' | 'endDate' | 'runDate', Date>>({
    startDate: new Date(),
    endDate: new Date(),
    runDate: new Date(),
  });

  useEffect(() => {
    setDivisionType(getFirstOptionValue(divisionTypeOptions) as DivisionTypesEnum);
    setGender(getFirstOptionValue(genderOptions) as RankListGenderEnum);
  }, [circuit]);

  const {
    divisionTypeOptions = [],
    genderOptions,
    formatOptions,
    ageGroupOptions,
    typeOptions,
    subDivisionOptions,
    NTRPLevelOptions = [],
    sectionOptions = [],
  } = useRankingOptions({ circuit, divisionType, gender }) as any;

  const applicableLevels = useMemo(() => getNTRPLevelOptions(ageGroup), [ageGroup]);

  const initialValues: InitialValues = useMemo(
    () => ({
      startDate: dates?.startDate ?? new Date(),
      endDate: dates?.endDate ?? new Date(),
      runDate: dates?.runDate ?? new Date(),
      circuit,
      type: getFirstOptionValue(typeOptions),
      gender: getFirstOptionValue(genderOptions),
      ageRestriction: getFirstOptionValue(ageGroupOptions),
      format: getFirstOptionValue(formatOptions),
      subDivision: getFirstOptionValue(subDivisionOptions),
      section: getFirstOptionValue(sectionOptions),
      divisionType,
      ntrpLevel: NTRPLevelOptions?.length ? getFirstOptionValue(NTRPLevelOptions) : '',
      itemLimit: '',
    }),
    [circuit, divisionType],
  ) as any;

  function isItemLimitError(type: ListTypeEnum, itemLimit: string): boolean {
    if (type === ListTypeEnum.DIRECT_ACCEPTANCE && !itemLimit) return true;
    return false;
  }

  async function handleSubmit(values: any, actions: FormikHelpers<any>) {
    const {
      gender,
      format,
      startDate,
      endDate,
      runDate,
      type,
      ageRestriction,
      circuit,
      divisionType,
      ntrpLevel,
      section,
      itemLimit,
      subDivision,
    } = values;

    if (isItemLimitError(type, itemLimit)) {
      actions.setFieldError('itemLimit', t('is required', { type: 'amount of players' }));
      return;
    }

    if (type === ListTypeEnum.DIRECT_ACCEPTANCE && !itemLimit.match(/^[0-9]+$/)) {
      actions.setFieldError('itemLimit', 'Amount of players must be a number');
      return;
    }

    const response = await generateRankList({
      variables: {
        input: {
          listType: type,
          playerType: circuit,
          ...getGender(circuit, gender, divisionType),
          ...getMatchFormat(circuit, type, format, gender),
          ...getPlayerLevel({
            circuit,
            divisionType,
            playerLevel: ntrpLevel,
            ageRestriction,
            subDivision,
          }),
          ...getFamilyCategory({ circuit, divisionType }),
          ...getAgeRestriction({
            playerType: circuit,
            divisionType,
            ageRestriction,
          }),
          ...getQuotaSection({ playerType: circuit, listType: type, section }),
          ...getDirectAcceptanceItemLimit({ listType: type, itemLimit }),
          ...getDateRange({ listType: type, startDate, endDate }),
          ...getRunDate({ listType: type, runDate }),
        },
      },
    });

    checkRankListStatus({ variables: { id: response?.data?.generateRankList?.id } });

    // we don't want to bother polling the rank list job if the initial rank list request failed validation
    if (!response?.data?.generateRankList?.jobComplete && !response?.data?.generateRankList?.jobErrors) {
      setPolling(true);
    }
  }

  const hasFormatField = (circuit: PlayerTypeEnum, type: ListTypeEnum): boolean => {
    switch (circuit) {
      case PlayerTypeEnum.JUNIOR:
        return type === ListTypeEnum.SEEDING || type === ListTypeEnum.YEAR_END;
      default:
        return true;
    }
  };

  const hasAgeGroupField = (circuit: PlayerTypeEnum, divisionType: DivisionTypesEnum) => {
    switch (circuit) {
      case PlayerTypeEnum.WHEELCHAIR:
        return divisionType === DivisionTypesEnum.AGE;
      default:
        return true;
    }
  };

  const hasSubDivisionField = (circuit: PlayerTypeEnum, gender: string, ageGroup: string) => {
    return (
      circuit === PlayerTypeEnum.WHEELCHAIR && gender === RankListGenderEnum.C && ageGroup === AgeRestrictionEnum.y18
    );
  };

  const hasGenderField = (circuit: PlayerTypeEnum) => {
    switch (circuit) {
      case PlayerTypeEnum.FAMILY:
        return false;
      default:
        return true;
    }
  };

  const hasSectionField = (type: ListTypeEnum) => {
    return type === ListTypeEnum.QUOTA;
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ values }) => (
        <Form>
          <Grid container direction="column">
            <Body size="md">{t('new ranking list explanation')}</Body>
            <BodyRegularBold spacing={{ margins: { xxs: 'bottom', md: 'top' } }}>
              {t('player category')}
            </BodyRegularBold>
            <DropdownField name="circuit" options={circuitOptions} onSelect={(o) => setCircuit(o.value)} />
            {values.circuit !== PlayerTypeEnum.JUNIOR && (
              <>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('division type')}
                </BodyRegularBold>
                <DropdownField
                  name="divisionType"
                  options={divisionTypeOptions}
                  onSelect={(o) => setDivisionType(o.value)}
                />
              </>
            )}
            <Grid item xs>
              <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('list type')}</BodyRegularBold>
              <DropdownField name="type" options={typeOptions} />
            </Grid>
            {displayDateRange(values.type) && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('start date')}
                  </BodyRegularBold>
                  <RangeContainer noMargin>
                    <DateField
                      name="startDate"
                      onChange={(date) => setDates({ ...dates, startDate: date })}
                      datePickerProps={{
                        disabled: false,
                        selected: new Date(values.startDate),
                        popperPlacement: 'top',
                        maxDate: new Date(),
                      }}
                    />
                  </RangeContainer>
                </Grid>
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('end date')}</BodyRegularBold>
                  <RangeContainer noMargin>
                    <DateField
                      name="endDate"
                      onChange={(date) => setDates({ ...dates, endDate: date })}
                      datePickerProps={{
                        disabled: false,
                        selected: new Date(values.endDate),
                        popperPlacement: 'top',
                        minDate: new Date(values.startDate),
                      }}
                    />
                  </RangeContainer>
                </Grid>
              </Grid>
            )}
            {values.type === ListTypeEnum.DIRECT_ACCEPTANCE && (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('amount of players')}
                </BodyRegularBold>
                <TextField
                  classes={{ root: styles.directAcceptanceInput }}
                  name="itemLimit"
                  InputProps={{ className: styles.directAcceptanceInput }}
                  type="tel"
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
            )}
            {values.type === ListTypeEnum.L2_QUALIFIER && (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('run date')}</BodyRegularBold>
                <RangeContainer noMargin>
                  <DateField
                    name="runDate"
                    onChange={(date) => setDates({ ...dates, runDate: date })}
                    datePickerProps={{
                      disabled: false,
                      selected: new Date(values.runDate),
                      popperPlacement: 'top',
                      maxDate: new Date(),
                    }}
                  />
                </RangeContainer>
              </Grid>
            )}
            {hasSectionField(values.type) && (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('section')}</BodyRegularBold>
                <DropdownField name="section" options={sectionOptions} />
              </Grid>
            )}
            <Grid container spacing={3}>
              {hasGenderField(values.circuit) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('gender')} *</BodyRegularBold>
                  <DropdownField name="gender" options={genderOptions} onSelect={(o) => setGender(o.value)} />
                </Grid>
              )}
              {hasAgeGroupField(values.circuit, values.divisionType as DivisionTypesEnum) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('age group')}
                  </BodyRegularBold>
                  <DropdownField
                    name="ageRestriction"
                    options={ageGroupOptions}
                    onSelect={(o) => setAgeGroup(o.value)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              {hasSubDivisionField(values.circuit, values.gender, values.ageRestriction) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('sub division')}
                  </BodyRegularBold>
                  <DropdownField name="subDivision" options={subDivisionOptions} />
                </Grid>
              )}
            </Grid>
            <Grid container>
              {hasFormatField(values.circuit, values.type) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('format')}</BodyRegularBold>
                  <DropdownField name="format" options={formatOptions} />
                </Grid>
              )}
            </Grid>
            {values.divisionType === DivisionTypesEnum.NTRP && circuit === PlayerTypeEnum.ADULT && (
              <>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('ntrp level')}</BodyRegularBold>
                <DropdownField name="ntrpLevel" options={applicableLevels} />
              </>
            )}
          </Grid>
          <CustomGrid container justify="flex-end" spacing={{ margins: { lg: 'top' } }}>
            <Button onClick={() => setRankingDialog(false)} type="button" level="tertiary">
              {t('cancel')}
            </Button>
            <Button type="submit" spacing={{ margins: { sm: 'left' } }} loading={generatingRankList || polling}>
              {t('create ranking list')}
            </Button>
          </CustomGrid>
          <CustomGrid container justify="flex-end">
            {error && <FormErrorMessage spacing={{ margins: { md: 'top' } }} message={t('generate ranklist error')} />}
            {!error && data?.rankListJob?.jobErrors && (
              <FormErrorMessage spacing={{ margins: { md: 'top' } }} message={data?.rankListJob?.jobErrors?.[0]} />
            )}
          </CustomGrid>
        </Form>
      )}
    </Formik>
  );
};
