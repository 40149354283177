import React, { Dispatch, SetStateAction, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Icon from 'src/components/icon/icon';
import { Body } from 'src/components/typography/typography';
import { defaultSorting } from 'src/components/usta-rankings/helpers';
import {
  BATCH_UPDATE_RANK_LIST_VISIBILITY,
  GET_RANKLIST_RUN_GROUPS,
} from 'src/components/usta-rankings/usta-ranking-queries';
import { RanklistRunGroups, RanklistRunGroups_ranklistRunGroups_items } from 'src/graphql-types/RanklistRunGroups';
import { ArrayParam, BooleanParam, useQueryParams } from 'use-query-params';

import * as styles from './usta-rankings-selected.module.less';

interface USTARankingsSelectedProps {
  numberOfSelected: number;
  selectedRanklistGroups: RanklistRunGroups_ranklistRunGroups_items[];
  setBatchDialog: Dispatch<SetStateAction<boolean>>;
  totalItems: number;
  refetch: any;
  filters?: any;
}

export const USTARankingsSelected: React.FC<USTARankingsSelectedProps> = ({
  numberOfSelected,
  selectedRanklistGroups,
  setBatchDialog,
  totalItems,
  refetch,
  filters,
}) => {
  const { t } = useTranslation();
  const [allSelected, setAllSelected] = useState(false);
  const [publishRunsDialog, setPublishRunsDialog] = useState(false);
  const [query, setQuery] = useQueryParams({
    selectedAllItems: BooleanParam,
    publishedLists: ArrayParam,
  });

  const {
    data: ranklistRunGroupsData,
    refetch: refetchRanklistGroups,
    loading: loadingRanklistRunGroups,
  } = useQuery<RanklistRunGroups>(GET_RANKLIST_RUN_GROUPS, {
    variables: {
      pageArgs: { limit: totalItems, skip: 0 },
      ranklistRunGroupFilter: filters,
      sort: defaultSorting,
    },
    client: meshGatewayClient,
    skip: allSelected,
  });

  const [batchUpdateRankListVisibility, { loading: updatingVisibility }] = useMutation<
    boolean,
    { visible: boolean; ids: string[] }
  >(BATCH_UPDATE_RANK_LIST_VISIBILITY, {
    client: meshGatewayClient,
  });

  const handlePublish = async () => {
    if (query.selectedAllItems) {
      await refetchRanklistGroups({
        pageArgs: { limit: totalItems, skip: 0 },
        ranklistRunGroupFilter: filters,
      });
      console.log(ranklistRunGroupsData);
      const latestRanklistIds = ranklistRunGroupsData?.ranklistRunGroups.items.map((r) => r.latestListId) ?? [];
      await batchUpdateRankListVisibility({
        variables: { ids: latestRanklistIds as string[], visible: true },
      });
      setQuery({ publishedLists: latestRanklistIds });
    } else {
      const latestRanklistIds = selectedRanklistGroups.map((ranklistGroup) => ranklistGroup.latestListId);
      await batchUpdateRankListVisibility({
        variables: { ids: latestRanklistIds as string[], visible: true },
      });
      setQuery({ publishedLists: latestRanklistIds });
    }
    refetch();
    setPublishRunsDialog(false);
  };

  return (
    <>
      <CustomGrid container className={styles.selectedContainer} justify="space-between" alignItems="center">
        <span className={styles.selectedText}>
          {numberOfSelected} {t('selected')}:
        </span>
        <button className={styles.selectedActionButton} onClick={() => setBatchDialog(true)}>
          <Icon name="md-add-circle-full" spacing={{ margins: { xxs: 'right' } }} className={styles.buttonIcon} />
          <span className={styles.selectActionButtonText}>{t('new run')}</span>
        </button>
        <button className={styles.selectedActionButton} onClick={() => setPublishRunsDialog(true)}>
          <Icon name="md-launch" spacing={{ margins: { xxs: 'right' } }} className={styles.buttonIcon} />
          <span className={styles.selectActionButtonText}>{t('publish latest runs')}</span>
        </button>
      </CustomGrid>
      <CustomDialog
        title={t('publish latest runs')}
        open={publishRunsDialog}
        hideX
        content={
          <CustomGrid container>
            <Body size="lg">{t('publish multiple runs', { amount: numberOfSelected })}</Body>
            <CustomGrid container justify="flex-end" spacing={{ margins: { md: 'top' } }}>
              <Button level="tertiary" onClick={() => setPublishRunsDialog(false)}>
                {t('cancel')}
              </Button>
              <Button
                onClick={handlePublish}
                spacing={{ margins: { sm: 'left' } }}
                loading={loadingRanklistRunGroups || updatingVisibility}
              >
                {t('confirm and publish')}
              </Button>
            </CustomGrid>
          </CustomGrid>
        }
        onClose={() => setPublishRunsDialog(false)}
      />
    </>
  );
};
