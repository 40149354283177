import React from 'react';

import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown from 'src/components/dropdown/dropdown';
import useCategoryFilter from 'src/components/rankings/utils/useCategoryFilter';
import { PositionSize } from 'src/hooks/spacing';
import { StaffScope, useHasScope } from 'src/utils/auth';

import { RunGroupFilter } from './ranking-run-groups';

interface RankingRunGroupsFilterProps {
  filters: RunGroupFilter;
  setFilter: (filter: RunGroupFilter) => void;
}

const RankingRunGroupsFilter: React.FC<RankingRunGroupsFilterProps> = ({ filters, setFilter }) => {
  const { t } = useTranslation();
  const rankingsAccess = useHasScope([StaffScope.RANKINGS]);

  const categoryFilter = useCategoryFilter(filters.category, filters, setFilter, t, rankingsAccess);

  const filterOptions = categoryFilter();

  return (
    <CustomGrid container spacing={{ margins: { sm: 'bottom' } }}>
      {filterOptions.map((filter, index) => {
        if (filter.hide) return null;
        const marginStyle = { [index !== 0 ? 'sm' : 'xxs']: 'left' } as PositionSize;
        return (
          <Dropdown
            key={filter.id}
            options={filter.options}
            spacing={{ margins: { md: 'bottom', ...marginStyle } }}
            selected={filters[filter.id]}
            onSelect={filter.onSelect}
            {...filter.props}
          />
        );
      })}
    </CustomGrid>
  );
};

export default RankingRunGroupsFilter;
