import React from 'react';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import * as styles from './notification-container.module.less';

const NotificationContainer = () => {
  return (
    <ToastContainer
      hideProgressBar
      pauseOnHover
      theme="colored"
      position="top-center"
      toastClassName={styles.toast}
      bodyClassName={styles.body}
    />
  );
};

export const withNotifications =
  <T extends object>(Component: React.ComponentType<T>) =>
  (props: T) => (
    <>
      <NotificationContainer />
      <Component {...props} />
    </>
  );

export default NotificationContainer;
