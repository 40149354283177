/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import { BatchRunDialog } from 'src/components/batch-run-dialog/batch-run-dialog';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import EmptyState from 'src/components/empty-state/empty-state';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { RankingsProcessing } from 'src/components/rankings-processing/rankings-processing';
import { Snackbar } from 'src/components/snackbar/snackbar';
import { transformSortDirection, useControlledQuery } from 'src/components/table-controls/table-controls';
import { USTARankingDialog } from 'src/components/usta-ranking-dialog/usta-ranking-dialog';
import { USTARankingFilters } from 'src/components/usta-ranking-filters/usta-ranking-filters';
import { FullSelection } from 'src/components/usta-rankings-full-selection/usta-rankings-full-selection';
import { USTARankingsSelected } from 'src/components/usta-rankings-selected/usta-rankings-selected';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { RankListJobs, RankListJobsVariables } from 'src/graphql-types/RankListJobs';
import {
  RanklistRunGroups,
  RanklistRunGroups_ranklistRunGroups_items as RanklistRunGroupsItem,
} from 'src/graphql-types/RanklistRunGroups';
import { useOrgHierarchy, useOrgLevel } from 'src/utils/auth';
import ROUTES from 'src/utils/routes';
import { retrieveRankingFilters, retrieveRowsPerPage, storeRankingFilters } from 'src/utils/storage/local-storage';
import { ArrayParam, BooleanParam, useQueryParams } from 'use-query-params';

import {
  defaultSorting,
  generateRunGroupId,
  getCheckedAllFields,
  getRanklistFilters,
  handleRowClick,
  useSelectedRegion,
} from './helpers';
import {
  BATCH_UPDATE_RANK_LIST_VISIBILITY,
  CANCEL_RANK_LIST_JOBS,
  GET_RANKLIST_RUN_GROUPS,
  RANK_LIST_JOBS,
} from './usta-ranking-queries';
import { useColumns } from './usta-rankings.columns';

const initialFiltersState = {
  category: '',
  division: '',
  format: '',
  listType: '',
  section: '',
  publishStatus: '',
  gender: '',
};

export const USTARankings: React.FC = () => {
  const { t } = useTranslation();
  const tableId = 'usta_rankings';
  const selectedRegion = useSelectedRegion();

  const { isNational } = useOrgLevel();
  const orgHierarchy = useOrgHierarchy();
  const storedRankingFilters = useMemo(() => retrieveRankingFilters(), []);
  const storedRowsPerPage = retrieveRowsPerPage();
  const [rankingDialog, setRankingDialog] = useState(false);
  const [batchRunDialog, setBatchRunDialog] = useState(false);
  const [processingVisible, setProcessingVisible] = useState(false);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
  const [polling, setPolling] = useState(false);
  const [query, setQuery] = useQueryParams({
    ranklistJobs: ArrayParam,
    selectedAllItems: BooleanParam,
    publishedLists: ArrayParam,
  });
  const [filters, setFilters] = useState({
    category: initialFiltersState?.category || '',
    division: initialFiltersState?.division || '',
    format: initialFiltersState?.format || '',
    listType: initialFiltersState?.listType || '',
    section: initialFiltersState?.section || '',
    publishStatus: initialFiltersState?.publishStatus || '',
    gender: initialFiltersState?.gender || '',
  });
  const [section, setSection] = useState('');
  const [toastDialog, setToastDialog] = useState(false);

  useEffect(() => {
    if (selectedRegion && orgHierarchy) {
      setSection(selectedRegion.value);
    }
  }, [selectedRegion, orgHierarchy]);

  // Reset filters when category changes
  useEffect(() => {
    setFilters({
      ...filters,
      division: '',
      format: '',
      listType: '',
      section: '',
      publishStatus: '',
      gender: '',
    });
  }, [filters?.category]);

  // Reset gender filter when format changes
  useEffect(() => {
    setFilters({ ...filters, gender: '' });
  }, [filters.format]);

  // Reset format filter when division changes
  useEffect(() => {
    setFilters({ ...filters, format: '' });
  }, [filters.division]);

  useEffect(() => {
    storeRankingFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (storedRankingFilters) {
      setFilters(storedRankingFilters);
    }
  }, [storedRankingFilters]);

  const controlledRanklistRunGroupsQueryOptions = useMemo(
    () => ({
      client: meshGatewayClient,
      getTotalItems: (d: RanklistRunGroups) => d.ranklistRunGroups?.totalItems,
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only' as any,
      transformVariables: (v: any) => {
        const isQuotaList = filters.listType === ListTypeEnum.QUOTA;
        const regionFilter = { section: isQuotaList ? section : undefined };

        if (isQuotaList && filters.section) {
          regionFilter['section'] = filters.section;
        }

        const queryFilters = {
          ranklistRunGroupFilter: getRanklistFilters({
            ...filters,
            ...regionFilter,
          }),
        };
        const { sorts, limit, offset } = v;

        if (!sorts)
          return {
            pageArgs: { limit, skip: offset },
            ...queryFilters,
            sort: defaultSorting,
          };

        const [{ sortDirection, property }] = sorts;
        return {
          pageArgs: { limit, skip: offset },
          ...queryFilters,
          sort: { field: property, direction: transformSortDirection(sortDirection) },
        };
      },
    }),
    [meshGatewayClient, filters, defaultSorting, section],
  );

  const {
    data: ranklistRunGroupsData,
    loading: ranklistRunGroupsDataLoading,
    refetch,
    controlProps,
  } = useControlledQuery<RanklistRunGroups>(GET_RANKLIST_RUN_GROUPS, controlledRanklistRunGroupsQueryOptions);

  const totalItems = controlProps?.totalItems ?? 0;

  const [cancelRankListJobs] = useMutation<any, RankListJobsVariables>(CANCEL_RANK_LIST_JOBS, {
    client: meshGatewayClient,
  });

  const [checkRankListStatus, { data, stopPolling, error: rankListError }] = useLazyQuery<RankListJobs>(
    RANK_LIST_JOBS,
    {
      pollInterval: 2500, // 2.5s
      client: meshGatewayClient,
    },
  );

  const [batchUpdateRankListVisibility] = useMutation<boolean, { visible: boolean; ids: string[] }>(
    BATCH_UPDATE_RANK_LIST_VISIBILITY,
    {
      client: meshGatewayClient,
    },
  );

  const ranklistRunGroups = useMemo(
    () =>
      ranklistRunGroupsData?.ranklistRunGroups.items.map((r) => ({
        ...r,
        id: generateRunGroupId({
          playerType: r.playerType,
          listType: r.listType,
          ageRestriction: r.ageRestriction,
          matchFormat: r.matchFormat,
          matchFormatType: r.matchFormatType,
          familyCategory: r.familyCategory,
          playerLevel: r.playerLevel,
          gender: r.gender,
          genderModifier: r.genderModifier,
          divisionType: r.divisionType,
          region: r.region,
        }),
      })) ?? [],
    [ranklistRunGroupsData],
  );

  // Handle ranklist jobs
  useEffect(() => {
    const allJobsCompleted = data?.rankListJobs?.every((job) => job?.jobComplete);

    if (allJobsCompleted) {
      stopPolling();
      setPolling(false);
      refetch();
    }

    if (rankListError) {
      stopPolling();
      setPolling(false);
    }
  }, [data, rankListError]);

  // Handle processing visibility
  useEffect(() => {
    if (query?.ranklistJobs) setProcessingVisible(true);
    if (!query?.ranklistJobs) setProcessingVisible(false);
  }, [query?.ranklistJobs]);

  useEffect(() => {
    if (query?.ranklistJobs && !data && !polling) {
      const ids = query.ranklistJobs;
      checkRankListStatus({ variables: { ids } });
      setPolling(true);
    }
  }, [query?.ranklistJobs]);

  // Set initial checkbox false values
  useEffect(() => {
    const checked = getCheckedAllFields(ranklistRunGroups, false);
    setChecked(checked);
  }, [ranklistRunGroups]);

  const isChecked = (id: string): boolean => Boolean(checked?.[id]);
  const [allChecked, setAllChecked] = useState(false);

  // Handle main checkbox control
  useEffect(() => {
    if (!allChecked && query.selectedAllItems) setQuery({ selectedAllItems: false });
    const checked = getCheckedAllFields(ranklistRunGroups, allChecked);
    setChecked(checked);
  }, [allChecked]);

  // Remove selectedAllItems query if any checkboxes change after it being applied
  useEffect(() => {
    if (query.selectedAllItems) setQuery({ selectedAllItems: false });
  }, [checked, allChecked]);

  // Reset checkboxes if rows per page changes
  useEffect(() => setAllChecked(false), [storedRowsPerPage]);
  useEffect(() => setAllChecked(false), [polling]);

  const cols: DataCols<RanklistRunGroupsItem & { id: string }> = useColumns({
    allChecked,
    checked,
    polling,
    setAllChecked,
    setChecked,
    isChecked,
  });

  const numberOfSelectedRows = Object.values(checked).filter(Boolean).length;

  const selectedRanklistGroups = useMemo(() => {
    const selectedIds = Object.entries(checked)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);

    return ranklistRunGroups.filter((g) => selectedIds.includes(g.id));
  }, [checked, ranklistRunGroupsData]);

  const rowsPerPage = retrieveRowsPerPage();
  const allItemsOnPageSelected = rowsPerPage === numberOfSelectedRows;
  const allJobsCompleted = data?.rankListJobs?.every((job) => job?.jobComplete);

  const closeProcessing = () => {
    setProcessingVisible(false);
    navigate(ROUTES.RANKINGS);
  };

  const cancelProcessingJobs = async () => {
    const pendingJobs = data?.rankListJobs?.filter((job) => !job?.jobComplete);
    const pendingJobIds = pendingJobs?.map((job) => job?.id as string) ?? [];

    try {
      cancelRankListJobs({ variables: { ids: pendingJobIds } }); // Needs to be awaited
    } catch (error) {
    } finally {
      stopPolling();
      setPolling(false);
      setProcessingVisible(false);
      setChecked(getCheckedAllFields(ranklistRunGroups, false));
      navigate(ROUTES.RANKINGS);
    }
  };

  const handleSelection = (state: boolean) => {
    if (!state) setAllChecked(false);
    setQuery({ selectedAllItems: state });
  };

  const handleUndoPublish = async () => {
    await batchUpdateRankListVisibility({
      variables: { ids: query.publishedLists as string[], visible: false },
    });
    setQuery({ publishedLists: null });
    setAllChecked(false);
    refetch();
  };

  useEffect(() => {
    setToastDialog(Boolean(query.publishedLists));
    if (query.publishedLists) setAllChecked(false);
  }, [query.publishedLists]);

  // Clear URL on dialog close
  useEffect(() => {
    if (!toastDialog) setQuery({ publishedLists: null });
  }, [toastDialog]);

  const RenderUSTARankingsSelected = () => (
    <USTARankingsSelected
      numberOfSelected={query.selectedAllItems ? totalItems : selectedRanklistGroups.length}
      selectedRanklistGroups={selectedRanklistGroups}
      setBatchDialog={setBatchRunDialog}
      totalItems={totalItems}
      refetch={refetch}
      filters={getRanklistFilters({
        ...filters,
        ...(section ? { section } : { section: undefined }),
        ...(!section && filters.listType === ListTypeEnum.QUOTA ? { section: filters.section } : {}),
      })}
    />
  );

  return (
    <PageMaxWidth>
      <Snackbar
        open={toastDialog}
        onClose={() => setToastDialog(false)}
        message={t('batch lists published', {
          total: query.selectedAllItems ? totalItems : query.publishedLists?.length ?? 0,
        })}
        actionButton={
          <Button level="secondary" onClick={handleUndoPublish} spacing={{ margins: { xs: 'right' } }}>
            {t('undo')}
          </Button>
        }
      />
      {processingVisible && (
        <RankingsProcessing
          action={allJobsCompleted ? closeProcessing : cancelProcessingJobs}
          ranklistJobs={data?.rankListJobs}
          setChecked={setChecked}
        />
      )}
      <Grid container direction="row" justifyContent="space-between">
        <PageHeader title={t('rankings')} />
        <Button
          spacing={{ margins: { auto: 'left' } }}
          onClick={() => setRankingDialog(true)}
          loading={polling}
          hide={!isNational}
        >
          {t('new ranking list')}
        </Button>
      </Grid>
      <Panel extendedPadding>
        <USTARankingFilters filters={filters} setFilters={setFilters} />
        {allItemsOnPageSelected && (
          <FullSelection
            selected={numberOfSelectedRows}
            total={totalItems}
            handleClick={() => handleSelection(!query.selectedAllItems)}
            state={query.selectedAllItems ? FullSelection.state.ACTIVE : FullSelection.state.INACTIVE}
          />
        )}
        {!ranklistRunGroupsDataLoading && ranklistRunGroups?.length === 0 ? (
          <EmptyState title={t('no rankings yet')} icon="xl-rankings" />
        ) : (
          <AdminTable
            data={ranklistRunGroups}
            loading={ranklistRunGroupsDataLoading}
            columns={cols}
            controls={controlProps}
            onRowClick={(row) => handleRowClick(row, query.ranklistJobs as string[])}
            hideTopPaginationInfo={numberOfSelectedRows !== 0 && !polling ? <RenderUSTARankingsSelected /> : undefined}
            columnSelectorId={tableId}
            filters={filters}
          />
        )}
        <CustomDialog
          title={t('add new ranking list')}
          open={rankingDialog}
          hideX
          content={<USTARankingDialog setRankingDialog={setRankingDialog} refetch={refetch} />}
          onClose={() => setRankingDialog(false)}
        />
        <CustomDialog
          title={t('add new run')}
          open={batchRunDialog}
          hideX
          content={
            <BatchRunDialog
              setDialog={setBatchRunDialog}
              selected={selectedRanklistGroups}
              polling={polling}
              setPolling={setPolling}
              checkRankListStatus={checkRankListStatus}
              ranklistJobs={data}
              totalItems={totalItems}
              filters={getRanklistFilters({
                ...filters,
                ...(section ? { section } : { section: undefined }),
                ...(!section && filters.listType === ListTypeEnum.QUOTA ? { section: filters.section } : {}),
              })}
            />
          }
          onClose={() => setBatchRunDialog(false)}
        />
      </Panel>
    </PageMaxWidth>
  );
};
