import React, { Dispatch, SetStateAction } from 'react';

import MuiAlert, { Color } from '@mui/material/Alert';
import MuiSnackbar, { SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar';
import cx from 'classnames';
import Icon, { IconProps } from 'src/components/icon/icon';

import * as styles from './snackbar.module.less';

export interface SnackbarProps extends MuiSnackbarProps {
  open: boolean;
  onClose: () => void;
  variant?: 'success';
  message: string;
  icon?: IconProps;
  actionButton?: React.ReactNode;
}

export function Snackbar({ open, onClose, variant = 'success', message, actionButton, icon, ...props }: SnackbarProps) {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ root: styles[`${variant}Floating`] }}
      {...props}
    >
      <aside className={styles[`${variant}Floating`]}>
        <button className={styles[`${variant}Close`]} onClick={onClose}>
          <Icon name="sm-close" />
        </button>
        <p className={styles[`${variant}Body`]}>
          {icon && <Icon {...icon} />}
          <span className={styles[`${variant}Message`]}>{message}</span>
          {actionButton}
        </p>
      </aside>
    </MuiSnackbar>
  );
}
