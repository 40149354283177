import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import RankingGroups from 'src/components/rankings/rankings-home';
import SEO from 'src/components/seo';
import TeamRankings from 'src/components/team-rankings/team-rankings';
import { USTARankings } from 'src/components/usta-rankings/usta-rankings';
import { getClientConfig } from 'src/config/config';
import { useV2Rankings } from 'src/utils/useV2Rankings';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Rankings" />
      <PrivateRoute>
        <Rankings />
      </PrivateRoute>
    </Layout>
  );
};

const Rankings = () => {
  const v2Rankings = useV2Rankings();
  const { useItaRankings } = getClientConfig();

  if (useItaRankings) return <TeamRankings />;

  return v2Rankings ? <RankingGroups /> : <USTARankings />;
};

export default IndexPage;
