import { useCallback } from 'react';

import { TFunction } from 'i18next';
import { getJuniorListTypeOptions, PublishStatusEnum } from 'src/components/usta-ranking-filters/usta-ranking-filters';
import { DivisionTypeEnum, ListTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { RankListGenderEnum } from 'src/graphql-types/globalUstaTypes';
import {
  FormatOptionsEnum,
  getFamilyDivisionTypeOptions,
  useCategoryOptions,
  useWheelchairDivisionTypeOptions,
} from 'src/utils/helper/rankings';

const ANY_FORMAT = 'any format';
const SINGLES = 'singles';
const INDIVIDUAL_DOUBLES = 'individual doubles';
const MIXED_INDIVIDUAL_DOUBLES = 'mixed individual doubles';
const TEAM_DOUBLES = 'team doubles';
const ANY_GENDER = 'any gender';
const MENS_REGULAR = 'mens regular';
const WOMEN_REGULAR = 'women regular';
const ANY_LIST_TYPE = 'any list type';

const getAdultFormat = (divisionType: DivisionTypeEnum, t: TFunction) => {
  if (divisionType === DivisionTypeEnum.AGE) {
    return [
      { value: '', label: t(ANY_FORMAT) },
      {
        value: FormatOptionsEnum.SINGLES,
        label: t(SINGLES),
      },
      {
        value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
        label: t(INDIVIDUAL_DOUBLES),
      },
      {
        value: FormatOptionsEnum.TEAM_DOUBLES,
        label: t(TEAM_DOUBLES),
      },
      {
        value: FormatOptionsEnum.MIXED_IND_DOUBLES,
        label: t(MIXED_INDIVIDUAL_DOUBLES),
      },
    ];
  }

  if (divisionType === DivisionTypeEnum.NTRP) {
    return [
      { value: '', label: t(ANY_FORMAT) },
      {
        value: FormatOptionsEnum.SINGLES,
        label: t(SINGLES),
      },
      {
        value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
        label: t(INDIVIDUAL_DOUBLES),
      },
      {
        value: FormatOptionsEnum.MIXED_IND_DOUBLES,
        label: t(MIXED_INDIVIDUAL_DOUBLES),
      },
    ];
  }

  return [
    { value: '', label: t(ANY_FORMAT) },
    {
      value: FormatOptionsEnum.SINGLES,
      label: t(SINGLES),
    },
    {
      value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
      label: t(INDIVIDUAL_DOUBLES),
    },
    {
      value: FormatOptionsEnum.TEAM_DOUBLES,
      label: t(TEAM_DOUBLES),
    },
    {
      value: FormatOptionsEnum.MIXED_IND_DOUBLES,
      label: t(MIXED_INDIVIDUAL_DOUBLES),
    },
  ];
};

const getPublishStatusOptions = (t: TFunction) => {
  return [
    { value: '', label: t('any published status') },
    {
      value: PublishStatusEnum.PUBLISHED,
      label: t('published'),
    },
    {
      value: PublishStatusEnum.HIDDEN,
      label: t('hidden'),
    },
  ];
};

const getGenderFilterOptions = (playerType: PlayerTypeEnum, t: TFunction) => {
  if (playerType === PlayerTypeEnum.ADULT) {
    return [
      { value: '', label: t(ANY_GENDER) },
      { value: RankListGenderEnum.M, label: t(MENS_REGULAR) },
      { value: RankListGenderEnum.F, label: t(WOMEN_REGULAR) },
    ];
  }

  if (playerType === PlayerTypeEnum.JUNIOR) {
    return [
      { value: '', label: t(ANY_GENDER) },
      { value: RankListGenderEnum.M, label: t('boys regular') },
      { value: RankListGenderEnum.F, label: t('girls regular') },
    ];
  }

  if (playerType === PlayerTypeEnum.FAMILY) {
    return [
      { value: '', label: t(ANY_GENDER) },
      { value: RankListGenderEnum.M, label: t(MENS_REGULAR) },
      { value: RankListGenderEnum.F, label: t(WOMEN_REGULAR) },
      { value: RankListGenderEnum.X, label: t('mixed') },
    ];
  }

  if (playerType === PlayerTypeEnum.WHEELCHAIR) {
    return [
      { value: '', label: t(ANY_GENDER) },
      { value: RankListGenderEnum.M, label: t(MENS_REGULAR) },
      { value: RankListGenderEnum.F, label: t(WOMEN_REGULAR) },
      { value: RankListGenderEnum.C, label: t('coed') },
    ];
  }

  return [{ value: '', label: t(ANY_GENDER) }];
};

const useCategoryFilter = (category, filters, setFilters, t, rankingsAccess) => {
  const familyDivisionTypeOptions = getFamilyDivisionTypeOptions(t);
  const wheelchairDivisionTypeOptions = useWheelchairDivisionTypeOptions();
  const adultFormatOptions = getAdultFormat(filters.division, t);
  const publishStatusOptions = getPublishStatusOptions(t);
  const genderFilterOptions = getGenderFilterOptions(filters.category, t);

  return useCallback(() => {
    const divisionOptionsMap = {
      [PlayerTypeEnum.ADULT]: [
        { value: '', label: t('any division type') },
        { value: DivisionTypeEnum.NTRP, label: t('ntrp') },
        { value: DivisionTypeEnum.AGE, label: t('age') },
      ],
      [PlayerTypeEnum.FAMILY]: [{ value: '', label: t('any division') }, ...familyDivisionTypeOptions],
      [PlayerTypeEnum.WHEELCHAIR]: [{ value: '', label: t('any division') }, ...wheelchairDivisionTypeOptions],
    };

    const formatOptionsMap = {
      [PlayerTypeEnum.ADULT]: adultFormatOptions,
      [PlayerTypeEnum.JUNIOR]: [
        { value: '', label: t(ANY_FORMAT) },
        { value: FormatOptionsEnum.SINGLES, label: t(SINGLES) },
        { value: FormatOptionsEnum.DOUBLES, label: t('doubles') },
        { value: FormatOptionsEnum.COMBINED, label: t('combined') },
      ],
      [PlayerTypeEnum.FAMILY]: [
        { value: '', label: t(ANY_FORMAT) },
        { value: FormatOptionsEnum.TEAM_DOUBLES, label: t(TEAM_DOUBLES) },
      ],
      [PlayerTypeEnum.WHEELCHAIR]: [
        { value: '', label: t(ANY_FORMAT) },
        { value: FormatOptionsEnum.SINGLES, label: t(SINGLES) },
        {
          value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
          label: t(INDIVIDUAL_DOUBLES),
        },
      ],
    };

    const listTypeOptionsMap = {
      [PlayerTypeEnum.ADULT]: [
        { value: '', label: t(ANY_LIST_TYPE) },
        { value: ListTypeEnum.STANDING, label: t('standing') },
        { value: ListTypeEnum.YEAR_END, label: t('year end') },
      ],
      [PlayerTypeEnum.JUNIOR]: getJuniorListTypeOptions({ rankingsAccess, t }),
      [PlayerTypeEnum.FAMILY]: [
        { value: '', label: t(ANY_LIST_TYPE) },
        { value: ListTypeEnum.STANDING, label: t('standing') },
        { value: ListTypeEnum.YEAR_END, label: t('year end') },
      ],
      [PlayerTypeEnum.WHEELCHAIR]: [
        { value: '', label: t(ANY_LIST_TYPE) },
        { value: ListTypeEnum.STANDING, label: t('standing') },
        { value: ListTypeEnum.YEAR_END, label: t('year end') },
      ],
    };

    return [
      {
        id: 'category',
        options: useCategoryOptions(),
        onSelect: (option) => setFilters({ ...filters, category: option.value }),
      },
      {
        id: 'division',
        options: divisionOptionsMap[category || PlayerTypeEnum.ADULT],
        onSelect: (option) => setFilters({ ...filters, division: option.value }),
        props: filters.category === '' ? { disabled: true } : {},
        hide: filters.category === PlayerTypeEnum.JUNIOR,
      },
      {
        id: 'format',
        options: formatOptionsMap[category || PlayerTypeEnum.ADULT],
        onSelect: (option) => setFilters({ ...filters, format: option.value }),
        props: filters.category === '' ? { disabled: true } : {},
      },
      {
        id: 'listType',
        options: listTypeOptionsMap[category || PlayerTypeEnum.ADULT],
        onSelect: (option) => setFilters({ ...filters, listType: option.value }),
        props: filters.category === '' ? { disabled: true } : {},
      },
      {
        id: 'publishStatus',
        options: publishStatusOptions,
        onSelect: (option) => setFilters({ ...filters, publishStatus: option.value }),
        props: filters.category === '' ? { disabled: true } : {},
      },
      {
        id: 'gender',
        options: genderFilterOptions,
        onSelect: (option) => setFilters({ ...filters, gender: option.value }),
        props: filters.category === '' ? { disabled: true } : {},
      },
    ];
  }, [
    t,
    familyDivisionTypeOptions,
    wheelchairDivisionTypeOptions,
    adultFormatOptions,
    rankingsAccess,
    category,
    filters,
    publishStatusOptions,
    genderFilterOptions,
    setFilters,
  ]);
};

export default useCategoryFilter;
