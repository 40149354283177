// extracted by mini-css-extract-plugin
export var bodyBase = "batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var bodyLarge = "batch-ranking-process-card-module--body-large--28e29 batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var bodyLargeBold = "batch-ranking-process-card-module--body-large-bold--5a1d3 batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var bodyRegular = "batch-ranking-process-card-module--body-regular--71ce3 batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var bodyRegularBold = "batch-ranking-process-card-module--body-regular-bold--9cd9e batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var bodySmall = "batch-ranking-process-card-module--body-small--ba927 batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var bodySmallBold = "batch-ranking-process-card-module--body-small-bold--f1ee5 batch-ranking-process-card-module--body-base--34d85 batch-ranking-process-card-module--site-font--61b7a";
export var borderTop = "batch-ranking-process-card-module--border-top--8a8e8";
export var breakWordContainer = "batch-ranking-process-card-module--break-word-container--9a7b3";
export var buttonIcon = "batch-ranking-process-card-module--button-icon--ed49a";
export var buttonIconBase = "batch-ranking-process-card-module--button-icon-base--59229";
export var clickLink = "batch-ranking-process-card-module--click-link--76e42";
export var dropdownBase = "batch-ranking-process-card-module--dropdown-base--56ec8";
export var dropdownSelectBase = "batch-ranking-process-card-module--dropdown-select-base--fba34 batch-ranking-process-card-module--text-input--036ad";
export var flexCol = "batch-ranking-process-card-module--flex-col--7f705";
export var formErrorMessage = "batch-ranking-process-card-module--form-error-message--23cc9";
export var h3 = "batch-ranking-process-card-module--h3--d71c3";
export var h4 = "batch-ranking-process-card-module--h4--89f51";
export var hoverLink = "batch-ranking-process-card-module--hover-link--3a769";
export var hoverRow = "batch-ranking-process-card-module--hover-row--1d972";
export var membershipContainer = "batch-ranking-process-card-module--membership-container--5525a batch-ranking-process-card-module--flex-col--7f705 batch-ranking-process-card-module--primary-border--6350a";
export var membershipHeader = "batch-ranking-process-card-module--membership-header--f26bf";
export var membershipHeading = "batch-ranking-process-card-module--membership-heading--5890b";
export var membershipLabel = "batch-ranking-process-card-module--membership-label--c94d6";
export var moreFiltersBorderClass = "batch-ranking-process-card-module--more-filters-border-class--16c77";
export var pageBg = "batch-ranking-process-card-module--page-bg--30362";
export var pointer = "batch-ranking-process-card-module--pointer--4b525";
export var primaryBorder = "batch-ranking-process-card-module--primary-border--6350a";
export var selectActionButtonText = "batch-ranking-process-card-module--select-action-button-text--7c121";
export var selectedActionButton = "batch-ranking-process-card-module--selected-action-button--84805 batch-ranking-process-card-module--unstyled-button--4a768";
export var selectedContainer = "batch-ranking-process-card-module--selected-container--969dc";
export var selectedText = "batch-ranking-process-card-module--selected-text--3b1f7";
export var shadowBoxLight = "batch-ranking-process-card-module--shadow-box-light--b3069";
export var siteFont = "batch-ranking-process-card-module--site-font--61b7a";
export var slideDownAndFade = "batch-ranking-process-card-module--slideDownAndFade--47c88";
export var slideLeftAndFade = "batch-ranking-process-card-module--slideLeftAndFade--6c3a4";
export var slideRightAndFade = "batch-ranking-process-card-module--slideRightAndFade--32472";
export var slideUpAndFade = "batch-ranking-process-card-module--slideUpAndFade--c765b";
export var statusDecoration = "batch-ranking-process-card-module--status-decoration--4f3fb";
export var textInput = "batch-ranking-process-card-module--text-input--036ad";
export var textInverted = "batch-ranking-process-card-module--text-inverted--8cf3f";
export var textMediumDark = "batch-ranking-process-card-module--text-medium-dark--6ecc0";
export var tooltipFont = "batch-ranking-process-card-module--tooltipFont--867a7";
export var unstyledButton = "batch-ranking-process-card-module--unstyled-button--4a768";