import { AgeRestrictionEnum } from 'src/graphql-types/globalRankingTypes';
import { td_PlayerLevelEnum } from 'src/graphql-types/TennisDataRankingTypes';

export const NTRPLevelOptions = [
  { label: '2.5', value: td_PlayerLevelEnum.L2_5 },
  { label: '3.0', value: td_PlayerLevelEnum.L3_0 },
  { label: '3.5', value: td_PlayerLevelEnum.L3_5 },
  { label: '4.0', value: td_PlayerLevelEnum.L4_0 },
  { label: '4.5', value: td_PlayerLevelEnum.L4_5 },
  { label: '5.0', value: td_PlayerLevelEnum.L5_0 },
  { label: '5.5', value: td_PlayerLevelEnum.L5_5 },
];

export const getNTRPLevelOptions = (ageGroup: AgeRestrictionEnum) => {
  switch (ageGroup) {
    case AgeRestrictionEnum.y40:
    case AgeRestrictionEnum.y55:
      return NTRPLevelOptions.filter(({ value }) => value !== td_PlayerLevelEnum.L5_5);
    default:
      return NTRPLevelOptions;
  }
};
